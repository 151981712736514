import { object } from 'prop-types';
import { graphql } from 'gatsby';
import { BrowsePA } from '../components/browse-by-pa';
import { Browse } from '../components/browse';
import { LawyersSection } from '../components/lawyers-section';
import { Text } from '../components/practice-area-text';
import { areBlogPostsInBothCountries, blogPostsFiltered, sortArticlesByDate } from '../helpers';
import { getTagsFromPosts } from '../helpers/misc';

function PracticeParentTemplate({ data }) {
  const titlePage = data?.wpPage?.title;
  const dataText = data?.wpPage?.template?.practiceParentConfiguration;
  const {
    eaPracticeParentIntroTitle: tHeader,
    eaPracticeParentIntroContent: tContent,
    eaPracticeShortName: sectionName,
  } = dataText;

  let paData = data?.wpPage?.wpChildren?.nodes;
  paData = paData.map(
    (
      { template, uri },
    ) => ({
      title: template?.practiceChildConfiguration?.eaPracticeShortName,
      uri,
      icon: template?.practiceChildConfiguration?.eaPracticeChildIcon,
    }),
  );

  let browseData = data?.relatedPosts.nodes && data?.relatedPosts.nodes.length > 0
    ? data?.relatedPosts.nodes : data?.wpPage?.practiceToPostsConnection?.nodes;
  browseData = browseData?.sort((a, b) => (a.title?.localeCompare(b.title)));

  const tools = browseData.filter(
    (item) => item.categories.nodes.filter(
      (node) => node.databaseId.toString() === process.env.GATSBY_EA_TOOLS_CATEGORY_ID,
    ).length,
  );

  let blogPosts = blogPostsFiltered(browseData);
  blogPosts = sortArticlesByDate(blogPosts);
  const tags = getTagsFromPosts(browseData);

  let caseResults = data?.wpPage?.caseResultsSnippets?.nodes;
  caseResults = caseResults.sort(
    (a, b) => b.caseResultSnippetSingleConfiguration?.beCrSnippetFeatured
  - a.caseResultSnippetSingleConfiguration?.beCrSnippetFeatured,
  );
  const browse = [
    {
      name: 'Blogs', type: 'Article', children: blogPosts,
    },
    { name: 'Topics', type: 'Index', children: tags },
    {
      name: 'Tools', type: 'Text', maxItems: 6, children: tools,
    },
    {
      name: 'Results', type: 'Case', children: caseResults, attorneys: data?.attorneys,
    }];

  browse?.forEach((tab, i) => {
    if (tab.type !== 'Case') {
      browse[i].texas = areBlogPostsInBothCountries(tab.children);
    }
  });

  const lawyersData = data?.wpPage?.practiceToTeamConnection?.nodes;

  return (
    <>
      <BrowsePA titleSection={tHeader} data={paData} />
      <Browse title={sectionName ? `${sectionName} Resources` : `${titlePage} Resources`} data={browse} />
      {!!lawyersData.length && <LawyersSection title={sectionName ? `${sectionName} Lawyers` : `${titlePage} Lawyers`} data={lawyersData} />}
      <Text
        header={tHeader}
        content={tContent}
      />
    </>
  );
}

export const query = graphql`
  query wpParentTemplate($uri: String, $databaseIds: [Int]) {
    relatedPosts: allWpPost(filter: {categories: {nodes: {elemMatch: {databaseId: {in: $databaseIds}}}}}
      sort: {order: DESC, fields: date}
      ) {
        nodes {
          id
          title
          uri
          date
          schemaMarkup {
            eaPostSchemaUploadDate
            eaPostSchemaVideoName
          }
          excerpt
          featuredImage {
            node {
              gatsbyImage(width:400)
            }
          }
          categories {
            nodes {
              databaseId
              wpParent {
                node {
                  name
                  wpParent {
                    node {
                      name
                    }
                  }
                }
              }
            }
          }
          tags {
            nodes {
              uri
              name
            }
          }
        }
    }
    wpPage(uri: {eq: $uri}) {
      ...SeoPage
      title
      template {
        ... on WpTemplate_PracticeParent {
          practiceParentConfiguration {
            eaPracticeParentIntroTitle
            eaPracticeParentIntroContent
            eaPracticeShortName
          }
        }
      }
      wpChildren {
        nodes {
          uri
          template {
            ... on WpTemplate_PracticeChild {
              practiceChildConfiguration {
                eaPracticeShortName
                eaPracticeChildIcon
              }
            }
          }
        }
      }
      practiceToTeamConnection {
        nodes {
          uri
          title
          featuredImage {
            node {
              gatsbyImage(width:400)
            }
          }
          attorneyTeamSingleConfiguration {
            eaTeamSpecialty
            eaTeamQuickBio
          }
        }
      }
      practiceToPostsConnection {
        nodes {
          id
          title
          uri
          date
          schemaMarkup {
            eaPostSchemaUploadDate
            eaPostSchemaVideoName
          }
          excerpt
          featuredImage {
            node {
              gatsbyImage(width:400)
            }
          }
          tags {
            nodes {
              uri
              name
            }
          }
          categories {
            nodes {
              databaseId
              wpParent {
                node {
                  name
                  wpParent {
                    node {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
      caseResultsSnippets {
        nodes {
          id
          title
          uri
          content
          caseResultSnippetSingleConfiguration {
            beCrSnippetAttorney {
            __typename
              ... on WpTeamMember {
                id
                title
                uri
              }
            }
            beCrSnippetClickthroughText
            beCrSnippetClickthroughUrl
            beCrSnippetFeatured
            beCrSnippetPracticeArea {
            __typename
              ... on WpPage {
                id
                title
                slug
                template {
                  templateName
                  ... on WpTemplate_PracticeChild {
                    practiceChildConfiguration {
                      eaPracticeShortName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    attorneys: allContentfulPageAttorney {
      nodes {
        title
        uri
        attorney{
          attorneyProfileImage {
            image {
              gatsbyImage(width:45, placeholder: NONE)
              file {
                url
              }
            }
            altText
          }
          firstName
          lastName
        }
      }
    }
  }
`;

PracticeParentTemplate.propTypes = {
  data: object,
};

PracticeParentTemplate.defaultProps = {
  data: {},
};

export default PracticeParentTemplate;
export { Head } from '../components/seo/seo';
