import { string } from 'prop-types';
import * as styles from './text-cases.module.scss';
import { Wrap } from '../sections-wrap';

function Text({ header, content }) {
  return (
    <div className={styles.textContainer}>
      <Wrap>
        <div className={styles.header}>
          <h2>{header}</h2>
        </div>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </Wrap>
    </div>
  );
}

Text.propTypes = {
  header: string,
  content: string,
};

Text.defaultProps = {
  header: '',
  content: '',
};

export { Text };
