import { string, array } from 'prop-types';
import { Link } from 'gatsby';
import sprites from './sprite.svg';
import * as styles from './browse-by-pa.module.scss';
import { Wrap } from '../sections-wrap';

function Icon({ id }) {
  return (
    <svg>
      <use
        xlinkHref={`${sprites}#${id}`}
      />
    </svg>
  );
}

function BrowsePA({ titleSection, data }) {
  data?.sort((a, b) => (a.title?.localeCompare(b.title)));
  return (
    <div className={styles.paContainer}>
      <Wrap>
        <div>
          <h1 className={styles.title}>{ titleSection }</h1>
        </div>
        <div>
          <div className={data.length > 4 ? styles.practiceGrid : styles.practiceFlex}>
            {data.map((item) => (
              <article className={styles.article} key={`box${item.title}`}>
                <Link to={item.uri}>
                  <Icon
                    id={item.template
                      ? item?.template?.practiceParentConfiguration?.eaPracticeParentIcon
                      : item?.icon?.trim()}
                  />
                  <h3 className={styles.title}>
                    {item.title}
                  </h3>
                </Link>
              </article>
            ))}
          </div>
        </div>
      </Wrap>
    </div>
  );
}

Icon.propTypes = {
  id: string.isRequired,
};

BrowsePA.propTypes = {
  titleSection: string,
  data: array,
};

BrowsePA.defaultProps = {
  titleSection: '',
  data: [],
};
export { BrowsePA };
